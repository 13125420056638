import { ThemeOptions } from 'interfaces/main';
import { user_getUserData, user_getUserPushNotificationPermission } from 'store/user/actions';
import { auth_countryLanguages, auth_hasJwtToken, auth_ipData } from 'store/auth/selectors';
import {
    system_educationModalState,
    system_loader,
    system_side_menu_state,
    system_theme,
    system_walkThroughAction,
} from 'store/system/selectors';
import {
    pushNotificationPermissionStatusSelector,
    user_loggedIn,
    user_userInfo,
    user_verified,
} from 'store/user/selectors';
import {
    AccountInfoParsed,
    GetIpDataResponse,
    PropChallenge,
    TradingPlatformAccountInfo,
} from '../services/generatedClientFromSwagger';
import { auth_getIpData } from 'store/auth/actions';
import {
    system_deviceTypeSize,
    system_getCountries,
    system_toggleBottomNavBar,
    system_toggleEducationModal,
} from 'store/system/actions';
import { DeviceType } from 'hooks/useDeviceType';
import { config_getEnv } from 'store/config/actions';
import { config_loader } from 'store/config/selectors';
import {
    prop__myPlans_getPlans,
    prop_getAdditionalInfoFromMonetaryTransactions,
} from 'features/prop/store/plans/actions';
import { prop_newChallege_actions } from 'features/prop/store/new-challenge/actions';
import {
    prop_myPlans_getChallengesLoader,
    prop_myPlans_getSelectedChallenge,
} from 'features/prop/store/plans/selectors';
import { prop_myPlans_getMainAccountTp } from 'features/prop/store/plans/selectors';
import { DepositResult, DepositTypes } from 'store/payment/interfaces';
import { payment_depositType } from 'store/payment/selectors';
import {
    payment_getPaymentTypes,
    payment_resetState,
    payment_setDepositResult,
    payment_setDepositType,
} from 'store/payment/actions';
import { prop_checkout_getPromoDiscountCodes } from 'features/prop/store/checkout/actions';
import { createContext, RefObject } from 'react';
import { platform_getMteIframeUrl } from 'store/platform/actions';

type RefContextType = RefObject<HTMLElement> | null;
export const RefContext = createContext<RefContextType>(null);

export interface AppProps {
    systemTheme: ThemeOptions;
    selectedChallenge: PropChallenge | undefined;
    hasJwtToken: boolean;
    systemLoader: boolean;
    loggedIn: boolean;
    user_getUserData: () => void;
    userVerified: boolean;
    ipData: GetIpDataResponse | undefined;
    auth_getIpData: () => void;
    countryLanguages: string[] | undefined;
    system_walkThroughAction: boolean;
    system_toggleBottomNavBar: (toggle: boolean) => void;
    system_side_menu_state: boolean;
    system_deviceTypeSize: (deviceType: DeviceType | '') => void;
    user_getUserPushNotificationPermission: () => void;
    pushNotificationPermissionStatus: 'default' | 'denied' | 'granted';
    config_loader: boolean;
    config_getEnv: () => void;
    prop__myPlans_getPlans: () => void;
    getNewGoal: () => void;
    mainTpAccount: TradingPlatformAccountInfo;
    getPaymentTypes: (tpId: string) => void;
    getPromoDiscountCodes: () => void;
    payment_resetState: () => void;
    payment_setDepositType: (depositType: { tabName: string; type: DepositTypes; subType?: string }) => void;
    payment_setDepositResult: (depositResult: DepositResult) => void;
    depositType:
        | {
              tabName: string;
              type: DepositTypes;
              subType?: string | undefined;
          }
        | undefined;
    system_getCountries: () => Promise<void>;

    system_toggleEducationModal: () => void;
    educationModalState: boolean;
    prop_getAdditionalInfoFromMonetaryTransactions: (tpId: string) => Promise<void>;
    platform_getMteIframeUrl: (accountName: string) => void;
    userInfo: AccountInfoParsed;
    getChallengesLoader: boolean | undefined;
}
export const mapStateToProps = (state) => ({
    systemTheme: system_theme(state),
    systemLoader: system_loader(state),
    hasJwtToken: auth_hasJwtToken(state),
    loggedIn: user_loggedIn(state),
    userVerified: user_verified(state),
    ipData: auth_ipData(state),
    countryLanguages: auth_countryLanguages(state),
    system_walkThroughAction: system_walkThroughAction(state),
    system_side_menu_state: system_side_menu_state(state),
    pushNotificationPermissionStatus: pushNotificationPermissionStatusSelector(state),
    config_loader: config_loader(state),
    mainTpAccount: prop_myPlans_getMainAccountTp(state),
    depositType: payment_depositType(state),
    educationModalState: system_educationModalState(state),
    selectedChallenge: prop_myPlans_getSelectedChallenge(state),
    userInfo: user_userInfo(state),
    getChallengesLoader: prop_myPlans_getChallengesLoader(state),
});

export const mapDispatchToProps = {
    user_getUserData: user_getUserData,
    auth_getIpData: auth_getIpData,
    system_toggleBottomNavBar,
    system_deviceTypeSize,
    user_getUserPushNotificationPermission,
    config_getEnv: config_getEnv,
    prop__myPlans_getPlans,
    getNewGoal: prop_newChallege_actions.getNewGoal,
    getPaymentTypes: payment_getPaymentTypes,
    getPromoDiscountCodes: prop_checkout_getPromoDiscountCodes,
    payment_resetState,
    payment_setDepositResult,
    payment_setDepositType,
    system_getCountries,
    system_toggleEducationModal,
    prop_getAdditionalInfoFromMonetaryTransactions,
    platform_getMteIframeUrl,
};
