import {
    PropChallenge,
    PropChallengeChallengeStatusEnum,
    PropChallengeChallengeTypeEnum,
    PropGoalTypeEnum,
} from 'services/generatedClientFromSwagger';
import { getAllSearchParamsAsObject } from './urlParamaters';

declare global {
    interface Window {
        $productFruits: any[];
        productFruits: any;
    }
}

// Initialize globals similarly to the index.html snippet.
window.$productFruits = window.$productFruits || [];
window.productFruits = window.productFruits || {};
window.productFruits.scrV = '2';
const { jwttoken, ...searchParams } = getAllSearchParamsAsObject();
let finalConfig: ProductFruitsConfig | undefined = undefined;
// Configuration interface for ProductFruits.
export enum ProductFruitsEvent {
    ChallengeFailed = 'Challenge Failed',
    ChallengePassed = 'Challenge Passed',
    FreeTrialPassed = 'Free Trial Passed',
    FreeTrialFailed = 'Free Trial Failed',
    PurchaseCompleteSuccessfully = 'Purchase Completed Successfully',
}

export interface ProductFruitsConfig {
    siteId: string; // e.g.
    language: string; // e.g. 'en'
    username?: string; // e.g. logged-in user's unique identifier (could be UDID)
    firstName?: string; // e.g. logged-in user's first name
    lastName?: string; // e.g. logged-in user's first name
    UDID?: string; // e.g. '8aa7c3180b9a0ccf'
    Account_GUID?: string; // e.g. '5ABBEA1D-0AE1-492F-B5BE-670A35BC93C6'
    Account_Size?: number; // e.g. 0
    country?: string; // e.g. 'USA'
    Affiliate?: string; // e.g. 'USA'
    UTM_Campaign?: string; // e.g. 'USA'
    props?: Record<string, any>; // Additional user properties (e.g. accountGUID, country, etc.)
}

/**
 * Dynamically loads a script by appending a <script> tag to the document head.
 * @param scriptUrl - URL of the script to load.
 * @returns A promise that resolves once the script is loaded.
 */
export const loadProductFruitsScript = (scriptUrl: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        // If the script is already present, resolve immediately.
        if (document.querySelector(`script[src="${scriptUrl}"]`)) {
            console.log(`Script already loaded: ${scriptUrl}`);
            resolve();
            return;
        }
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.async = true;
        script.onload = () => {
            console.log(`Successfully loaded ProductFruits script from ${scriptUrl}`);
            resolve();
        };
        script.onerror = () => {
            console.error('Failed to load ProductFruits script from', scriptUrl);
            reject(new Error('Failed to load ProductFruits script'));
        };
        document.head.appendChild(script);
        console.log(`Script tag appended for ${scriptUrl}`);
    });
};

/**
 * Initializes ProductFruits.
 * If no configuration is provided, default values are used.
 * @param config - Optional configuration to override default settings.
 */
export const initProductFruits = async (config?: Partial<ProductFruitsConfig>): Promise<void> => {
    const defaultConfig: any = {
        props: {
            ...searchParams,
            ...(config?.props || {}),
        },
    };
    finalConfig = { ...defaultConfig, ...config } as ProductFruitsConfig;
    const scriptUrl = 'https://app.productfruits.com/static/script.js';

    try {
        await loadProductFruitsScript(scriptUrl);
    } catch (error) {
        console.error('Error loading ProductFruits script:', error);
        return;
    }

    // Ensure the global queue is available.
    if (!window.$productFruits) {
        window.$productFruits = [];
    }

    console.log('Sending initialization payload to ProductFruits:', finalConfig);
    // Push the initialization command into the global queue.
    window.$productFruits.push(['init', finalConfig?.siteId, finalConfig?.language, finalConfig]);
    console.log('Initialization command pushed:', window.$productFruits);
};

/**
 * Utility function to send an event to ProductFruits.
 * Assumes that sending an event is done via a push command.
 * @param eventName - Name of the event.
 * @param description - Description of the event.
 * @param properties - Additional event properties.
 */
export const sendEvent = (eventName: string, properties: Record<string, any>): void => {
    const eventPayload = { ...finalConfig, ...properties };
    console.log(`Sending event: ${eventName}`, eventPayload);
    if (window.productFruits.api?.events?.track) {
        window.productFruits.api.events.track(eventName, eventPayload, { forwardToIntegrations: true });
    }
};

export const sendPurchaseCompletedEvent = (properties: Record<string, any>): void => {
    console.log('Preparing to send "Purchase Completed Successfully" event with properties:', properties);
    sendEvent(ProductFruitsEvent.PurchaseCompleteSuccessfully, properties);
};

export const sendProductFruitsEvent = (challenge: PropChallenge) => {
    const isFree = challenge.challengeType === PropChallengeChallengeTypeEnum.FreeTrial;
    const { funds: Account_Size, id: Challenge_Id, tpName } = challenge;
    switch (challenge?.challengeStatus) {
        case PropChallengeChallengeStatusEnum.ManuallyDisqualified:
        case PropChallengeChallengeStatusEnum.LackOfTradingActivity:
        case PropChallengeChallengeStatusEnum.ChallengeDeleted:
        case PropChallengeChallengeStatusEnum.ChallengeTerminatedInCompetition:
        case PropChallengeChallengeStatusEnum.HitMaxDailyLossPercent:
        case PropChallengeChallengeStatusEnum.HitMaxFloatingCashLoss:
        case PropChallengeChallengeStatusEnum.OpenPendingOrderOverWeekend:
        case PropChallengeChallengeStatusEnum.HitTerminationEquityLevel:
            return sendEvent(isFree ? ProductFruitsEvent.FreeTrialFailed : ProductFruitsEvent.ChallengeFailed, {
                Account_Size,
                Challenge_Id,
                tpName,
            });
        case PropChallengeChallengeStatusEnum.HitEquityTarget:
            return sendEvent(isFree ? ProductFruitsEvent.FreeTrialPassed : ProductFruitsEvent.ChallengePassed, {
                Account_Size,
                Challenge_Id,
                tpName,
            });
        default:
            return;
    }
};
