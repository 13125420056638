import React from 'react';
import { useTheme } from '@material-ui/core';
import {
    Book,
    MenuBookRounded,
    AcUnit,
    AccessAlarm,
    PersonOutlined,
    VisibilityOutlined,
    PhoneOutlined,
    GpsFixedOutlined,
    KeyboardArrowUp,
    KeyboardArrowDown,
    KeyboardArrowRight,
    KeyboardArrowLeft,
    Clear,
    ExitToAppOutlined,
    DateRange,
    RadioButtonUnchecked,
    RadioButtonChecked,
    ArrowBack,
    FileCopy,
    BorderColorRounded,
    MoreHoriz,
    StarRounded,
    StarBorderRounded,
    Create,
    ArrowDownward,
    ArrowUpward,
    SortOutlined,
    Schedule,
} from '@material-ui/icons';
import { IconProps as IconPropsMat, SvgIcon } from '@material-ui/core';
import GoogleIcon from 'assets/icons/google_icon.svg?react';
import FacebookIcon from 'assets/icons/facebook_icon.svg?react';
import LogoLabelIcon from 'assets/icons/logo_label.svg?react';
import AccountSettings from 'assets/icons/account_settings.svg?react';
import ClientSupport from 'assets/icons/client_support.svg?react';
import Dashboard from 'assets/icons/dashboard.svg?react';
import MyProfile from 'assets/icons/my_profile.svg?react';
import MyWallet from 'assets/icons/my_wallet.svg?react';
import Platforms from 'assets/icons/platforms.svg?react';
import TradingAccounts from 'assets/icons/trading_accounts.svg?react';
import TradingAnalysis from 'assets/icons/trading_analysis.svg?react';
import UploadDocuments from 'assets/icons/upload_documents.svg?react';
import Notification from 'assets/icons/notification.svg?react';
import Menu from 'assets/icons/menu.svg?react';
import CreditCard from 'assets/icons/credit-card.svg?react';
import Paypal from 'assets/icons/paypal.svg?react';
import Transfer from 'assets/icons/transfer.svg?react';
import Check from 'assets/icons/check.svg?react';
import ExportCsv from 'assets/icons/export-csv.svg?react';
import AddSort from 'assets/icons/addSort.svg?react';
import AddSortFull from 'assets/icons/addSortFull.svg?react';
import Search from 'assets/icons/search.svg?react';
import Cancel from 'assets/icons/cancel.svg?react';
import Sort from 'assets/icons/sort.svg?react';
import SortDown from 'assets/icons/sort-down.svg?react';
import CheckboxIntermidiate from 'assets/icons/checkbox intermidiate.svg?react';
import Triangle from 'assets/icons/triangle.svg?react';
import ElectronicPay from 'assets/icons/electronic_pay.svg?react';
import Withdraw from 'assets/icons/withdraw_icon.svg?react';
import Deposit from 'assets/icons/deposit_icon.svg?react';
import Trade from 'assets/icons/trade_icon.svg?react';
import SuggestionBuy from 'assets/icons/suggestion_buy.svg?react';
import SuggestionSell from 'assets/icons/suggestion_sell.svg?react';
import Star from 'assets/icons/star_icon.svg?react';
import Copy from 'assets/icons/copy.svg?react';
import Pdf from 'assets/icons/pdf.svg?react';
import Email from 'assets/icons/email.svg?react';
import ContactEmail from 'assets/icons/contact_email.svg?react';
import ContactPhone from 'assets/icons/contact_phone.svg?react';
import Regulation from 'assets/icons/regulation.svg?react';
import Failed from 'assets/icons/failed_icon.svg?react';
import Success from 'assets/icons/success_icon.svg?react';
import DepositLinks from 'assets/icons/deposit-links.svg?react';
import DepositCrypto from 'assets/icons/deposit-crypto.svg?react';
import AccountPersonalSettings from 'assets/icons/account_personal_settings.svg?react';
import Lock from 'assets/icons/lock.svg?react';
import EnvelopChecked from 'assets/icons/envelop_checked.svg?react';
import Ellipse from 'assets/icons/ellipse.svg?react';
import Garbage from 'assets/icons/garbage.svg?react';
import Crypto from 'assets/icons/crypto_icon.svg?react';
import CryptoOverview from 'assets/icons/crypto_overview.svg?react';
import CryptoTrade from 'assets/icons/crypto_trade.svg?react';
import CryptoExchange from 'assets/icons/crypto_exchange.svg?react';
import CryptoHistory from 'assets/icons/crypto_history.svg?react';
import CryptoWallet from 'assets/icons/crypto_wallet.svg?react';
import CryptoMarkets from 'assets/icons/crypto_markets.svg?react';
import CryptoSell from 'assets/icons/crypto_sell.svg?react';
import CryptoBuy from 'assets/icons/crypto_buy.svg?react';
import CryptoTradeGraph from 'assets/icons/crypto_trade-graph.svg?react';
import NewDelete from 'assets/icons/new-delete.svg?react';
import ChartType from 'assets/icons/chartType.svg?react';
import ChartTypeArea from 'assets/icons/chartType_area.svg?react';
import ChartTypeBars from 'assets/icons/chartType_bars.svg?react';
import ChartTypeCandles from 'assets/icons/chartType_candles.svg?react';
import ChartTypeLines from 'assets/icons/chartType_lines.svg?react';
import ChartClean from 'assets/icons/chartClean.svg?react';
import ChartIndicators from 'assets/icons/chartIndicators.svg?react';
import Warning from 'assets/icons/warning.svg?react';
import GreenCheck from 'assets/icons/crypto_green-check.svg?react';
import RedX from 'assets/icons/crypto_redX.svg?react';
import CryptoWarning from 'assets/icons/crypto_warning.svg?react';
import Print from 'assets/icons/print.svg?react';
import WifiOff from 'assets/icons/wifiOff.svg?react';
import WalkThroughStep1 from 'assets/icons/Piqk/walkThrough_step_1.svg?react';
import WalkThroughStep2 from 'assets/icons/Piqk/walkThrough_step_2.svg?react';
import HomeFilledPiqk from 'assets/icons/Piqk/home_filled_piqk.svg?react';
import HomeEmptyPiqk from 'assets/icons/Piqk/home_empty_piqk.svg?react';
import MarketsFilledPiqk from 'assets/icons/Piqk/markets_filled_piqk.svg?react';
import MarketsEmptyPiqk from 'assets/icons/Piqk/markets_empty_piqk.svg?react';
import PositionsFilledPiqk from 'assets/icons/Piqk/positions_filled_piqk.svg?react';
import PositionsEmptyPiqk from 'assets/icons/Piqk/positions_empty_piqk.svg?react';
import FundsFilledPiqk from 'assets/icons/Piqk/funds_filled_piqk.svg?react';
import FundsEmptyPiqk from 'assets/icons/Piqk/funds_empty_piqk.svg?react';
import VisibilityOffPiqk from 'assets/icons/Piqk/visibility_off_piqk.svg?react';
import MailOutlinePiqk from 'assets/icons/Piqk/mail_outline_piqk.svg?react';
import LockPiqk from 'assets/icons/Piqk/lock_piqk.svg?react';
import ClosePiqk from 'assets/icons/Piqk/close_piqk.svg?react';
import BankWirePiqk from 'assets/icons/Piqk/bank_wire_piqk.svg?react';
import BitcoinCryptoPiqk from 'assets/icons/Piqk/bitcoin_crypto_piqk.svg?react';
import CreditCardPiqk from 'assets/icons/Piqk/credit_card_piqk.svg?react';
import FileCopyPiqk from 'assets/icons/Piqk/fileCopy_piqk.svg?react';
import BlueVectorPiqk from 'assets/icons/Piqk/blue_vector_piqk.svg?react';
import CheckboxPiqk from 'assets/icons/Piqk/checkbox_piqk.svg?react';
import CheckedIconPiqk from 'assets/icons/Piqk/checked_icon_piqk.svg?react';
import HelpOutlinePiqk from 'assets/icons/Piqk/help_outline_piqk.svg?react';
import GrayVectorPiqk from 'assets/icons/Piqk/gray_vector_piqk.svg?react';
import GreenDonePiqk from 'assets/icons/Piqk/green_done_piqk.svg?react';
import RedClosePiqk from 'assets/icons/Piqk/red_close_piqk.svg?react';
import TwitterSharePiqk from 'assets/icons/Piqk/twitter_share_piqk.svg?react';
import LinkDinSharePiqk from 'assets/icons/Piqk/linkDin_share_piqk.svg?react';
import FaceBookSharePiqk from 'assets/icons/Piqk/faceBook_share_piqk.svg?react';
import LogOutPiqk from 'assets/icons/Piqk/logOut_piqk.svg?react';
import VerifiedPiqk from 'assets/icons/Piqk/verified_piqk.svg?react';
import NotVerifiedPiqk from 'assets/icons/Piqk/not_verified_piqk.svg?react';
import HelpCenterPiqk from 'assets/icons/Piqk/help_center_piqk.svg?react';
import SupportPiqk from 'assets/icons/Piqk/support_piqk.svg?react';
import FaqPiqk from 'assets/icons/Piqk/FAQ_piqk.svg?react';
import TermsOfUsePiqk from 'assets/icons/Piqk/terms_of_use_piqk.svg?react';
import Info from 'assets/icons/Piqk/info_piqk.svg?react';
import NetdaniaTradeTradeSmart from 'assets/icons/Piqk/netdaniaTrade_TradeSmart_poUp.svg?react';
import MenuPiqk from 'assets/icons/Piqk/menu_piqk.svg?react';
import BackChervonPiqk from 'assets/icons/Piqk/back_chervon_piqk.svg?react';
import BellRinging from 'assets/icons/Piqk/bell_ringing.svg?react';
import DepositCryptoWarningPiqk from 'assets/icons/Piqk/deposit_crypto_warning_piqk.svg?react';
import ChevronDown from 'assets/icons/Piqk/chevron_down_piqk.svg?react';
import SocialOutline from 'assets/icons/social_outline.svg?react';
import SocialPiqk from 'assets/icons/Piqk/social.svg?react';
import SocialFilledPiqk from 'assets/icons/Piqk/social-filled.svg?react';
import DarkModePiqk from 'assets/icons/Piqk/dark_mode_piqk.svg?react';
import LightModePiqk from 'assets/icons/Piqk/light_mode_piqk.svg?react';
import TourGuideBookPiqk from 'assets/icons/Piqk/tour_guide_book_piqk.svg?react';
import CreditCardX from 'assets/icons/Piqk/credit_card_x.svg?react';
import Trophy from 'assets/icons/Piqk/trophy.svg?react';
import TrophyFilled from 'assets/icons/trophy-filled.svg?react';
import WithdrawConfirmation from 'assets/icons/Piqk/withdraw_confirmation.svg?react';
import PendingDeposit from 'assets/icons/Piqk/pending_deposit.svg?react';
import AlertTriangle from 'assets/icons/Piqk/alert_triangle.svg?react';
import Bank from 'assets/icons/Piqk/bank.svg?react';
import CreditCardUpload from 'assets/icons/Piqk/credit_card_upload.svg?react';
import NoNotificationImagePiqk from 'assets/icons/Piqk/no_notification_image_piqk.svg?react';
import MessageChatSquare from 'assets/icons/message_chat_square.svg?react';
import TwitterShareColoredPiqk from 'assets/icons/Piqk/twitter_share_colored_piqk.svg?react';
import LinkDinShareColoredPiqk from 'assets/icons/Piqk/linkDin_share_colored_piqk.svg?react';
import FaceBookShareColoredPiqk from 'assets/icons/Piqk/faceBook_share_colored_piqk.svg?react';
import Share from 'assets/icons/Piqk/share.svg?react';
import CheckSquareBroken from 'assets/icons/Piqk/check_square_broken.svg?react';
import CoinStack from 'assets/icons/Piqk/coin_stack.svg?react';
import UserRight from 'assets/icons/Piqk/user_right.svg?react';
import SortingArrowPiqk from 'assets/icons/Piqk/sorting_arrow_piqk.svg?react';
import AppleAppPiqk from 'assets/icons/Piqk/apple_app_piqk.svg?react';
import AndroidAppPiqk from 'assets/icons/Piqk/android_app_piqk.svg?react';
import StationAppPiqk from 'assets/icons/Piqk/station_app_piqk.svg?react';
import TrophyProp from 'assets/icons/trophy.svg?react';
import NewTrophyProp from 'assets/icons/newTrophy.svg?react';
import AnnotationQuestion from 'assets/icons/annotationQuestion.svg?react';
import CurrencyDollarCircle from 'assets/icons/currencyDollarCircle.svg?react';
import ArrowDown from 'assets/icons/arrowDown.svg?react';
import ArrowUp from 'assets/icons/arrowUp.svg?react';
import MoreVertical from 'assets/icons/more_vertical.svg?react';
import ArrowRight from 'assets/icons/arrow-right.svg?react';
import QRCode from 'assets/icons/qr-code.svg?react';
import CheckCircle from 'assets/icons/check-circle.svg?react';
import CheckSvg from 'assets/icons/check_svg.svg?react';
import ChevronDownNew from 'assets/icons/chevronDown.svg?react';
import TradingProp from 'assets/icons/tradingProp.svg?react';
import ChallengeType1Icon from 'assets/icons/challenge-type1-icon.svg?react';
import ChallengeType2Icon from 'assets/icons/challenge-type2-icon.svg?react';
import TableEmptyState from 'assets/icons/tableEmptyState.svg?react';
import Chart from 'assets/icons/chart.svg?react';
import Lightning from 'assets/icons/lightning.svg?react';
import Gift from 'assets/icons/gift.svg?react';
import CopySvg from 'assets/icons/copy_svg.svg?react';
import ArrowRightType from 'assets/icons/arrowRight.svg?react';
import ArrowLeftType from 'assets/icons/arrowLeft.svg?react';
import Plus from 'assets/icons/plus.svg?react';
import InfoCircle from 'assets/icons/info_circle.svg?react';
import Refresh from 'assets/icons/refresh.svg?react';
import CoinsStacked from 'assets/icons/coins_stacked.svg?react';
import SuccessIcon from 'assets/icons/success_icon.svg?react';
import DotsVertical from 'assets/icons/dots_vertical.svg?react';
import ZapCircle from 'assets/icons/zap_circle.svg?react';
import Money from 'assets/icons/money.svg?react';
import Target from 'assets/icons/target.svg?react';
import Starts from 'assets/icons/stars.svg?react';
import Calender from 'assets/icons/calendar.svg?react';
import CalenderDate from 'assets/icons/calendar-date.svg?react';
import CoinsHand from 'assets/icons/coins_hand.svg?react';
import CurrencyDollar from 'assets/icons/Piqk/currency_dollar.svg?react';
import TargetOne from 'assets/icons/Piqk/target_one.svg?react';
import PropChallengeBanner from 'assets/icons/prop_challenge_banner.svg?react';
import ClosePiqkAlert from 'assets/icons/Piqk/close_piqk_alert.svg?react';
import InfoIconProp from 'assets/icons/Piqk/info_icon_prop.svg?react';
import ShoppingBag from 'assets/icons/Piqk/shopping_bag.svg?react';
import CloseDrawer from 'assets/icons/Piqk/close_drawer.svg?react';
import ClockRewind from 'assets/icons/clock_rewind.svg?react';
import ChevronLeftBack from 'assets/icons/Piqk/chevron_left_back.svg?react';
import AlertCircleIcon from 'assets/icons/Piqk/alert_circle_icon.svg?react';
import CheckCircleIcon from 'assets/icons/Piqk/check_circle_icon.svg?react';
import XClose from 'assets/icons/Piqk/x_close.svg?react';
import ChevronUpDouble from 'assets/icons/Piqk/chevron_up_double.svg?react';
import CoinsSwap from 'assets/icons/Piqk/coins_swap.svg?react';
import ShieldDollar from 'assets/icons/Piqk/shield_dollar.svg?react';
import SearchWithBG from 'assets/icons/Piqk/search_with_bg.svg?react';
import SearchWithBgDark from 'assets/icons/Piqk/search_with_bg_dark.svg?react';
import TableEmptyStateDark from 'assets/icons/tableEmptyStateDark.svg?react';
import NewCalendar from 'assets/icons/newCalendar.svg?react';
import Zap from 'assets/icons/zap.svg?react';

import RefreshIcon from 'assets/icons/refresh_icon.svg?react';
import Sale from 'assets/icons/Piqk/sale.svg?react';
import BankNote from 'assets/icons/Piqk/bank_note.svg?react';

import NavBarChartSquare from 'assets/icons/Piqk/nav_bar_chart_square.svg?react';
import NavBarLineChart from 'assets/icons/Piqk/nav_bar_line_chart.svg?react';
import NavBarPlus from 'assets/icons/Piqk/nav_bar_plus.svg?react';
import NavBarSettings from 'assets/icons/Piqk/nav_bar_settings.svg?react';
import NavBarTrophy from 'assets/icons/Piqk/nav_bar_trophy.svg?react';
import NavBarChartSquareFilled from 'assets/icons/Piqk/nav_bar_chart_square_filled.svg?react';
import NavBarLineChartFilled from 'assets/icons/Piqk/nav_bar_line_chart_filled.svg?react';
import NavBarSettingsFilled from 'assets/icons/Piqk/nav_bar_settings_filled.svg?react';
import NavBarTrophyFilled from 'assets/icons/Piqk/nav_bar_trophy_filled.svg?react';
import FileIcon from 'assets/icons/file.svg?react';
import Trash from 'assets/icons/trash.svg?react';
import CheckFilled from 'assets/icons/check_filled.svg?react';
import UploadCloud from 'assets/icons/upload_cloud.svg?react';
import PieChart from 'assets/icons/pieChart.svg?react';
import LightBulbProp from 'assets/icons/lightbulbProp.svg?react';
import StatsProp from 'assets/icons/stats_prop.svg?react';
import ProgressBar from 'assets/icons/progress_bar.svg?react';

import StepDisabledLight from 'assets/icons/step_disabled_light.svg?react';
import StepDisabledDark from 'assets/icons/step-disabled_dark.svg?react';
import StepPassedLight from 'assets/icons/step_passed_light.svg?react';
import StepPassedDark from 'assets/icons/step_passed_dark.svg?react';
import StepActiveLight from 'assets/icons/step_active_light.svg?react';
import StepActiveDark from 'assets/icons/step_active_dark.svg?react';
import StepFailedLight from 'assets/icons/step_failed_light.svg?react';
import StepFailedDark from 'assets/icons/step_failed_dark.svg?react';

import RetryDisabled from 'assets/icons/retry_disabled.svg?react';
import ShareBox from 'assets/icons/share-01.svg?react';
import Congratulations from 'assets/icons/congratulations.svg?react';
import VerificationInProgress from 'assets/icons/verificationInProgress.svg?react';
import VerificationComplete from 'assets/icons/verificationComplete.svg?react';
import AccountUnavailable from 'assets/icons/accountUnavailable.svg?react';
import VerificationFailed from 'assets/icons/verificationFailed.svg?react';
import ShieldTick from 'assets/icons/shield_tick.svg?react';
import ShieldPlus from 'assets/icons/shield_plus.svg?react';
import Confetti from 'assets/icons/confetti.svg?react';
import CongratulationsKyc from 'assets/icons/congratulations_kyc.svg?react';
import CoinsStacked2 from 'assets/icons/coins-stacked-03.svg?react';
import CalenderPlus from 'assets/icons/calendar-plus.svg?react';
import Coins from 'assets/icons/coins.svg?react';
import MagicLinkIcon from 'assets/icons/magic_link_icon.svg?react';
import ModalMagicClose from 'assets/icons/modal_magic_close.svg?react';
import MagicLinkMail from 'assets/icons/magic_link_mail.svg?react';

export const Icons = {
    trash: (args) => <Trash {...args} />,
    fileIcon: (args) => <FileIcon {...args} />,
    check_filled: (args) => <CheckFilled {...args} />,

    book: Book,
    menuBook: MenuBookRounded,
    darkModeIcon: (args) => <DarkModePiqk {...args} />,
    lightModeIcon: (args) => <LightModePiqk {...args} />,
    acUnit: AcUnit,
    accessAlarm: AccessAlarm,
    person: PersonOutlined,
    visible: VisibilityOutlined,
    un_visible: (args) => <VisibilityOffPiqk {...args} />,
    phone: PhoneOutlined,
    gps_fixed: GpsFixedOutlined,
    chevron_up: (args) => <KeyboardArrowUp {...args} />,
    chevron_down: (args) => <KeyboardArrowDown {...args} />,
    chevron_left: (args) => <KeyboardArrowLeft {...args} />,
    chevron_right: (args) => <KeyboardArrowRight {...args} />,
    logout: ExitToAppOutlined,
    date: DateRange,
    radio_unchecked: RadioButtonUnchecked,
    radio_checked: RadioButtonChecked,
    arrow_back: ArrowBack,
    fileCopy: FileCopy,
    arrow_down: ArrowDownward,
    arrow_up: ArrowUpward,
    pen: (args) => <BorderColorRounded {...args} style={{ fontSize: 'inherit' }} />,
    backup: (args) => <UploadCloud {...args} style={{ fontSize: '6rem' }} />,
    close: (args) => <Clear {...args} fontSize="large" style={{ margin: '-1px' }} />,
    google: (args) => <SvgIcon component={GoogleIcon} {...args} viewBox="0 0 512 512" />,
    facebook: (args) => <SvgIcon component={FacebookIcon} {...args} viewBox="0 0 112.196 112.196" />,
    logo_label: (args) => <LogoLabelIcon />,
    account_settings: (args) => <AccountSettings />,
    client_support: (args) => <ClientSupport {...args} />,
    dashboard: (args) => <Dashboard />,
    my_profile: (args) => <MyProfile />,
    my_wallet: (args) => <MyWallet />,
    platforms: (args) => <Platforms />,
    trading_accounts: (args) => <TradingAccounts />,
    trading_analysis: (args) => <TradingAnalysis />,
    upload_documents: (args) => <UploadDocuments />,
    notification: (args) => <Notification />,
    menu: (args) => <Menu />,
    transfer: (args) => <Transfer />,
    credit_card: (args) => <CreditCard />,
    paypal: (args) => <Paypal />,
    check: (args) => <Check />,
    add_sort: (args) => <AddSort />,
    add_sort_full: (args) => <AddSortFull />,
    export_csv: (args) => <ExportCsv />,
    search: (args) => <Search />,
    cancel: (args) => <Cancel />,
    sort: (args) => <Sort />,
    sort_down: (args) => <SortDown />,
    checkbox_intermidiate: (args) => <CheckboxIntermidiate />,
    triangle: (args) => <Triangle />,
    electronic_pay: (args) => <ElectronicPay />,
    withdraw: (args) => <Withdraw />,
    deposit: (args) => <Deposit />,
    trade: (args) => <Trade />,
    suggestion_buy: (args) => <SuggestionBuy />,
    suggestion_sell: (args) => <SuggestionSell />,
    star: (args) => <Star />,
    star_rounded: (args) => <StarRounded {...args} />,
    star_border_rounded: (args) => <StarBorderRounded {...args} />,
    copy: (args) => <Copy />,
    pdf: (args) => <Pdf />,
    email: (args) => <Email />,
    custom: (args) => <span>{args?.children?.label}</span>,
    contact_email: (args) => <ContactEmail />,
    contact_phone: (args) => <ContactPhone />,
    regulation: (args) => <Regulation />,
    failed: (args) => <Failed />,
    success: (args) => <Success {...args} />,
    deposit_links: (args) => <DepositLinks />,
    deposit_crypto: (args) => <DepositCrypto />,
    account_personal_settings: (args) => <AccountPersonalSettings />,
    lock: (args) => <Lock />,
    envelop_checked: (args) => <EnvelopChecked />,
    ellipse: (args) => <Ellipse />,
    garbage: (args) => <Garbage />,
    crypto: () => <Crypto />,
    crypto_overview: () => <CryptoOverview />,
    crypto_trade: () => <CryptoTrade />,
    crypto_exchange: () => <CryptoExchange />,
    crypto_history: () => <CryptoHistory />,
    crypto_wallet: () => <CryptoWallet />,
    crypto_markets: () => <CryptoMarkets />,
    crypto_sell: () => <CryptoSell />,
    crypto_buy: () => <CryptoBuy />,
    more_horiz: (args) => <MoreHoriz {...args} style={{ fontSize: '3rem' }} />,
    cryptoTradeGraph: (args) => <CryptoTradeGraph />,
    newDelete: (args) => <NewDelete {...args} />,
    chartType: (args) => <ChartType {...args} />,
    chartType_Area: (args) => <ChartTypeArea {...args} />,
    chartType_Bars: (args) => <ChartTypeBars {...args} />,
    chartType_Candles: (args) => <ChartTypeCandles {...args} />,
    chartType_Lines: (args) => <ChartTypeLines {...args} />,
    chartClean: (args) => <ChartClean {...args} />,
    chartIndicators: (args) => <ChartIndicators {...args} />,
    warning: (args) => <Warning {...args} />,
    create: (args) => <Create {...args} style={{ fontSize: '2.4rem' }} />,
    crypto_green_check: (args) => <GreenCheck {...args} />,
    crypto_redX: (args) => <RedX {...args} />,
    crypto_warning: (args) => <CryptoWarning {...args} />,
    print: (args) => <Print />,
    sort_new: (args) => <SortOutlined {...args} />,
    schedule: (args) => <Schedule {...args} />,
    WifiOff: (args) => <WifiOff {...args} />,
    walkThroughStep1: (args) => <WalkThroughStep1 {...args} />,
    walkThroughStep2: (args) => <WalkThroughStep2 {...args} />,
    home_filled_piqk: (args) => <HomeFilledPiqk {...args} />,
    home_empty_piqk: (args) => <HomeEmptyPiqk {...args} />,
    markets_filled_piqk: (args) => <MarketsFilledPiqk {...args} />,
    markets_empty_piqk: (args) => <MarketsEmptyPiqk {...args} />,
    positions_filled_piqk: (args) => <PositionsFilledPiqk {...args} />,
    positions_empty_piqk: (args) => <PositionsEmptyPiqk {...args} />,
    funds_filled_piqk: (args) => <FundsFilledPiqk {...args} />,
    funds_empty_piqk: (args) => <FundsEmptyPiqk {...args} />,
    bank_wire_piqk: (args) => <BankWirePiqk {...args} />,
    bitcoin_crypto_piqk: (args) => <BitcoinCryptoPiqk {...args} />,
    credit_card_piqk: (args) => <CreditCardPiqk {...args} />,
    fileCopy_piqk: (args) => <FileCopyPiqk {...args} />,
    mail_outline_piqk: (args) => <MailOutlinePiqk {...args} />,
    lock_piqk: (args) => <LockPiqk {...args} />,
    close_piqk: (args) => <ClosePiqk {...args} />,
    blue_vector_piqk: (args) => <BlueVectorPiqk {...args} />,
    checkbox_piqk: (args) => <CheckboxPiqk {...args} />,
    checked_icon_piqk: (args) => <CheckedIconPiqk {...args} />,
    help_outline_piqk: (args) => <HelpOutlinePiqk {...args} />,
    gray_vector_piqk: (args) => <GrayVectorPiqk {...args} />,
    green_done_piqk: (args) => <GreenDonePiqk {...args} />,
    red_close_piqk: (args) => <RedClosePiqk {...args} />,
    twitter_share_piqk: (args) => <TwitterSharePiqk {...args} />,
    linkDin_share_piqk: (args) => <LinkDinSharePiqk {...args} />,
    faceBook_share_piqk: (args) => <FaceBookSharePiqk {...args} />,
    logOut_piqk: (args) => <LogOutPiqk {...args} />,
    verified_piqk: (args) => <VerifiedPiqk {...args} />,
    not_verified_piqk: (args) => <NotVerifiedPiqk {...args} />,
    help_center_piqk: (args) => <HelpCenterPiqk {...args} />,
    support_piqk: (args) => <SupportPiqk {...args} />,
    faq_piqk: (args) => <FaqPiqk {...args} />,
    terms_of_use_piqk: (args) => <TermsOfUsePiqk {...args} />,
    info: (args) => <Info {...args} />,
    netdania_trade_trade_smart: (args) => <NetdaniaTradeTradeSmart {...args} />,
    menu_piqk: (args) => <MenuPiqk {...args} />,
    bell_ringing: (args) => <BellRinging {...args} />,
    deposit_crypto_warning_piqk: (args) => <DepositCryptoWarningPiqk {...args} />,
    back_chervon_piqk: (args) => <BackChervonPiqk {...args} />,
    chevron_down_piqk: (args) => <ChevronDown {...args} />,
    credit_card_x: (args) => <CreditCardX {...args} />,
    trophy: (args) => <Trophy {...args} />,
    trophy_filled: (args) => <TrophyFilled {...args} />,
    withdraw_confirmation: (args) => <WithdrawConfirmation {...args} />,
    pending_deposit: (args) => <PendingDeposit {...args} />,
    alert_triangle: (args) => <AlertTriangle {...args} />,
    bank: (args) => <Bank {...args} />,
    credit_card_upload: (args) => <CreditCardUpload {...args} />,
    social_outline: (args) => <SocialOutline {...args} />,
    social_piqk: (args) => <SocialPiqk {...args} />,
    social_filled_piqk: (args) => <SocialFilledPiqk {...args} />,
    tour_guide_book_piqk: (args) => <TourGuideBookPiqk {...args} />,
    no_notification_image_piqk: (args) => <NoNotificationImagePiqk {...args} />,
    message_chat_square: (args) => <MessageChatSquare {...args} />,
    twitter_share_colored_piqk: (args) => <TwitterShareColoredPiqk {...args} />,
    linkDin_share_colored_piqk: (args) => <LinkDinShareColoredPiqk {...args} />,
    faceBook_share_colored_piqk: (args) => <FaceBookShareColoredPiqk {...args} />,
    share: (args) => <Share {...args} />,
    check_square_broken: (args) => <CheckSquareBroken {...args} />,
    coin_stack: (args) => <CoinStack {...args} />,
    user_right: (args) => <UserRight {...args} />,
    sorting_arrow_piqk: (args) => <SortingArrowPiqk {...args} />,
    apple_app_piqk: (args) => <AppleAppPiqk {...args} />,
    android_app_piqk: (args) => <AndroidAppPiqk {...args} />,
    station_app_piqk: (args) => <StationAppPiqk {...args} />,
    trophy_prop: (args) => <TrophyProp {...args} />,
    new_trophy_prop: (args) => <NewTrophyProp {...args} />,
    annotationQuestion: (args) => <AnnotationQuestion {...args} />,
    currencyDollarCircle: (args) => <CurrencyDollarCircle {...args} />,
    arrowDown: (args) => <ArrowDown {...args} />,
    arrowUp: (args) => <ArrowUp {...args} />,
    more_vertical: (args) => <MoreVertical {...args} />,
    arrowRight: (args) => <ArrowRight {...args} />,
    arrowLeft: (args) => (
        <ArrowRight {...args} style={{ ...(args?.style ? args.style : {}), transform: 'rotate(180deg)' }} />
    ),
    qRCode: (args) => <QRCode {...args} />,
    checkCircle: (args) => <CheckCircle {...args} />,
    CheckSvg: (args) => <CheckSvg {...args} />,
    chevronDownNew: (args) => <ChevronDownNew {...args} />,
    tradingProp: (args) => <TradingProp {...args} />,
    challengeType1Icon: (args) => <ChallengeType1Icon {...args} />,
    challengeType2Icon: (args) => <ChallengeType2Icon {...args} />,
    tableEmptyState: (args) => <TableEmptyState {...args} />,
    chart: (args) => <Chart {...args} />,
    lightning: (args) => <Lightning {...args} />,
    gift: (args) => <Gift {...args} />,
    copy_svg: (args) => <CopySvg {...args} />,
    arrowRightType: (args) => <ArrowRightType {...args} />,
    arrowLeftType: (args) => <ArrowLeftType {...args} />,
    plus: (args) => <Plus {...args} />,
    info_circle: (args) => <InfoCircle {...args} />,
    refresh: (args) => <Refresh {...args} />,
    coins_stacked: (args) => <CoinsStacked {...args} />,
    successIcon: (args) => <SuccessIcon {...args} />,
    dots_vertical: (args) => <DotsVertical {...args} />,
    zap_circle: (args) => <ZapCircle {...args} />,
    money: (args) => <Money {...args} />,
    target: (args) => <Target {...args} />,
    starts: (args) => <Starts {...args} />,
    calender: (args) => <Calender {...args} />,
    calenderDate: (args) => <CalenderDate {...args} />,
    coinsHand: (args) => <CoinsHand {...args} />,
    currencyDollar: (args) => <CurrencyDollar {...args} />,
    targetOne: (args) => <TargetOne {...args} />,
    prop_challenge_banner: (args) => <PropChallengeBanner {...args} />,
    close_piqk_alert: (args) => <ClosePiqkAlert {...args} />,
    info_icon_prop: (args) => <InfoIconProp {...args} />,
    shopping_bag: (args) => <ShoppingBag {...args} />,
    close_drawer: (args) => <CloseDrawer {...args} />,
    clock_rewind: (args) => <ClockRewind {...args} />,
    chevron_left_back: (args) => <ChevronLeftBack {...args} />,
    alert_circle_icon: (args) => <AlertCircleIcon {...args} />,
    check_circle_icon: (args) => <CheckCircleIcon {...args} />,
    x_close: (args) => <XClose {...args} />,
    chevron_up_double: (args) => <ChevronUpDouble {...args} />,
    coins_swap: (args) => <CoinsSwap {...args} />,
    shield_dollar: (args) => <ShieldDollar {...args} />,
    search_with_bg: (args) => <SearchWithBG {...args} />,
    search_with_bg_dark: (args) => <SearchWithBgDark {...args} />,
    sale: (args) => <Sale {...args} />,
    bank_note: (args) => <BankNote {...args} />,
    tableEmptyStateDark: (args) => <TableEmptyStateDark {...args} />,

    nav_bar_chart_square: (args) => <NavBarChartSquare {...args} />,
    nav_bar_line_chart: (args) => <NavBarLineChart {...args} />,
    nav_bar_plus: (args) => <NavBarPlus {...args} />,
    nav_bar_settings: (args) => <NavBarSettings {...args} />,
    nav_bar_trophy: (args) => <NavBarTrophy {...args} />,
    nav_bar_chart_square_filled: (args) => <NavBarChartSquareFilled {...args} />,
    nav_bar_line_chart_filled: (args) => <NavBarLineChartFilled {...args} />,
    nav_bar_settings_filled: (args) => <NavBarSettingsFilled {...args} />,
    nav_bar_trophy_filled: (args) => <NavBarTrophyFilled {...args} />,
    newCalendar: (args) => <NewCalendar {...args} />,
    zap: (args) => <Zap {...args} />,
    refresh_icon: (args) => <RefreshIcon {...args} />,
    pieChart: (args) => <PieChart {...args} />,
    lightbulbProp: (args) => <LightBulbProp {...args} />,
    stats_prop: (args) => <StatsProp {...args} />,
    progress_bar: (args) => <ProgressBar {...args} />,
    step_disabled_light: (args) => <StepDisabledLight {...args} />,
    step_disabled_dark: (args) => <StepDisabledDark {...args} />,
    step_passed_light: (args) => <StepPassedLight {...args} />,
    step_passed_dark: (args) => <StepPassedDark {...args} />,
    step_active_light: (args) => <StepActiveLight {...args} />,
    step_active_dark: (args) => <StepActiveDark {...args} />,
    step_failed_light: (args) => <StepFailedLight {...args} />,
    step_failed_dark: (args) => <StepFailedDark {...args} />,

    congratulations: (args) => <Congratulations {...args} />,
    verificationInProgress: (args) => <VerificationInProgress {...args} />,
    verificationComplete: (args) => <VerificationComplete {...args} />,
    accountUnavailable: (args) => <AccountUnavailable {...args} />,
    verificationFailed: (args) => <VerificationFailed {...args} />,
    shield_tick: (args) => <ShieldTick {...args} />,
    shield_plus: (args) => <ShieldPlus {...args} />,
    confetti: (args) => <Confetti {...args} />,
    congratulationsKyc: (args) => <CongratulationsKyc {...args} />,
    coinsStacked2: (args) => <CoinsStacked2 {...args} />,
    calenderPlus: (args) => <CalenderPlus {...args} />,
    retry_disabled: (args) => <RetryDisabled {...args} />,
    shareBox: (args) => <ShareBox {...args} />,
    coins: (args) => <Coins {...args} />,
    magic_link_icon: (args) => <MagicLinkIcon {...args} />,
    modal_magic_close: (args) => <ModalMagicClose {...args} />,
    magic_link_mail: (args) => <MagicLinkMail {...args} />,
};

export enum IconsType {
    trash = 'trash',
    check_filled = 'check_filled',
    fileIcon = 'fileIcon',
    fileCopy = 'fileCopy',
    book = 'book',
    menuBook = 'menuBook',
    darkModeIcon = 'darkModeIcon',
    lightModeIcon = 'lightModeIcon',
    acUnit = 'acUnit',
    accessAlarm = 'accessAlarm',
    person = 'person',
    visible = 'visible',
    un_visible = 'un_visible',
    phone = 'phone',
    gps_fixed = 'gps_fixed',
    google = 'google',
    facebook = 'facebook',
    logo_label = 'logo_label',
    chevron_up = 'chevron_up',
    chevron_down = 'chevron_down',
    chevron_right = 'chevron_right',
    chevron_left = 'chevron_left',
    account_settings = 'account_settings',
    client_support = 'client_support',
    dashboard = 'dashboard',
    my_profile = 'my_profile',
    my_wallet = 'my_wallet',
    platforms = 'platforms',
    trading_accounts = 'trading_accounts',
    trading_analysis = 'trading_analysis',
    upload_documents = 'upload_documents',
    notification = 'notification',
    close = 'close',
    menu = 'menu',
    logout = 'logout',
    date = 'date',
    transfer = 'transfer',
    credit_card = 'credit_card',
    paypal = 'paypal',
    radio_unchecked = 'radio_unchecked',
    radio_checked = 'radio_checked',
    check = 'check',
    custom = 'custom',
    arrow_back = 'arrow_back',
    add_sort = 'add_sort',
    add_sort_full = 'add_sort_full',
    export_csv = 'export_csv',
    search = 'search',
    cancel = 'cancel',
    sort = 'sort',
    sort_down = 'sort_down',
    checkbox_intermidiate = 'checkbox_intermidiate',
    triangle = 'triangle',
    electronic_pay = 'electronic_pay',
    trade = 'trade',
    withdraw = 'withdraw',
    deposit = 'deposit',
    suggestion_buy = 'suggestion_buy',
    suggestion_sell = 'suggestion_sell',
    star = 'star',
    star_rounded = 'star_rounded',
    star_border_rounded = 'star_border_rounded',
    copy = 'copy',
    pdf = 'pdf',
    email = 'email',
    contact_email = 'contact_email',
    contact_phone = 'contact_phone',
    regulation = 'regulation',
    failed = 'failed',
    success = 'success',
    backup = 'backup',
    deposit_links = 'deposit_links',
    deposit_crypto = 'deposit_crypto',
    account_personal_settings = 'account_personal_settings',
    lock = 'lock',
    envelop_checked = 'envelop_checked',
    ellipse = 'ellipse',
    garbage = 'garbage',
    pen = 'pen',
    crypto = 'crypto',
    crypto_overview = 'crypto_overview',
    crypto_trade = 'crypto_trade',
    crypto_exchange = 'crypto_exchange',
    crypto_history = 'crypto_history',
    crypto_wallet = 'crypto_wallet',
    crypto_markets = 'crypto_markets',
    crypto_sell = 'crypto_sell',
    crypto_buy = 'crypto_buy',
    more_horiz = 'more_horiz',
    cryptoTradeGraph = 'cryptoTradeGraph',
    newDelete = 'newDelete',
    chartType = 'chartType',
    chartType_Area = 'chartType_Area',
    chartType_Bars = 'chartType_Bars',
    chartType_Candles = 'chartType_Candles',
    chartType_Lines = 'chartType_Lines',
    chartClean = 'chartClean',
    chartIndicators = 'chartIndicators',
    warning = 'warning',
    create = 'create',
    crypto_green_check = 'crypto_green_check',
    crypto_redX = 'crypto_redX',
    crypto_warning = 'crypto_warning',
    arrow_down = 'arrow_down',
    arrow_up = 'arrow_up',
    sort_new = 'sort_new',
    print = 'print',
    schedule = 'schedule',
    WifiOf = 'WifiOff',
    walkThroughStep1 = 'walkThroughStep1',
    walkThroughStep2 = 'walkThroughStep2',
    home_filled_piqk = 'home_filled_piqk',
    home_empty_piqk = 'home_empty_piqk',
    markets_filled_piqk = 'markets_filled_piqk',
    markets_empty_piqk = 'markets_empty_piqk',
    positions_filled_piqk = 'positions_filled_piqk',
    positions_empty_piqk = 'positions_empty_piqk',
    funds_filled_piqk = 'funds_filled_piqk',
    funds_empty_piqk = 'funds_empty_piqk',
    bank_wire_piqk = 'bank_wire_piqk',
    bitcoin_crypto_piqk = 'bitcoin_crypto_piqk',
    credit_card_piqk = 'credit_card_piqk',
    fileCopy_piqk = 'fileCopy_piqk',
    twitter_share_piqk = 'twitter_share_piqk',
    linkDin_share_piqk = 'linkDin_share_piqk',
    faceBook_share_piqk = 'faceBook_share_piqk',
    logOut_piqk = 'logOut_piqk',
    verified_piqk = 'verified_piqk',
    not_verified_piqk = 'not_verified_piqk',
    help_center_piqk = 'help_center_piqk',
    support_piqk = 'support_piqk',
    faq_piqk = 'faq_piqk',
    terms_of_use_piqk = 'terms_of_use_piqk',
    info = 'info',
    netdania_trade_trade_smart = 'netdania_trade_trade_smart',
    menu_piqk = 'menu_piqk',
    mail_outline_piqk = 'mail_outline_piqk',
    lock_piqk = 'lock_piqk',
    close_piqk = 'close_piqk',
    blue_vector_piqk = 'blue_vector_piqk',
    checkbox_piqk = 'checkbox_piqk',
    checked_icon_piqk = 'checked_icon_piqk',
    help_outline_piqk = 'help_outline_piqk',
    gray_vector_piqk = 'gray_vector_piqk',
    green_done_piqk = 'green_done_piqk',
    red_close_piqk = 'red_close_piqk',
    bell_ringing = 'bell_ringing',
    deposit_crypto_warning_piqk = 'deposit_crypto_warning_piqk',
    back_chervon_piqk = 'back_chervon_piqk',
    chevron_down_piqk = 'chevron_down_piqk',
    credit_card_x = 'credit_card_x',
    trophy = 'trophy',
    trophy_filled = 'trophy_filled',
    withdraw_confirmation = 'withdraw_confirmation',
    pending_deposit = 'pending_deposit',
    alert_triangle = 'alert_triangle',
    bank = 'bank',
    credit_card_upload = 'credit_card_upload',
    social_outline = 'social_outline',
    social_piqk = 'social_piqk',
    social_filled_piqk = 'social_filled_piqk',
    tour_guide_book_piqk = 'tour_guide_book_piqk',
    no_notification_image_piqk = 'no_notification_image_piqk',
    message_chat_square = 'message_chat_square',
    twitter_share_colored_piqk = 'twitter_share_colored_piqk',
    linkDin_share_colored_piqk = 'linkDin_share_colored_piqk',
    faceBook_share_colored_piqk = 'faceBook_share_colored_piqk',
    share = 'share',
    check_square_broken = 'check_square_broken',
    coin_stack = 'coin_stack',
    user_right = 'user_right',
    sorting_arrow_piqk = 'sorting_arrow_piqk',
    apple_app_piqk = 'apple_app_piqk',
    android_app_piqk = 'android_app_piqk',
    station_app_piqk = 'station_app_piqk',
    trophy_prop = 'trophy_prop',
    new_trophy_prop = 'new_trophy_prop',
    annotationQuestion = 'annotationQuestion',
    currencyDollarCircle = 'currencyDollarCircle',
    arrowDown = 'arrowDown',
    arrowUp = 'arrowUp',
    more_vertical = 'more_vertical',
    arrowRight = 'arrowRight',
    arrowLeft = 'arrowLeft',
    qRCode = 'qRCode',
    checkCircle = 'checkCircle',
    CheckSvg = 'CheckSvg',
    chevronDownNew = 'chevronDownNew',
    tradingProp = 'tradingProp',
    challengeType1Icon = 'challengeType1Icon',
    challengeType2Icon = 'challengeType2Icon',
    tableEmptyState = 'tableEmptyState',
    chart = 'chart',
    lightning = 'lightning',
    gift = 'gift',
    copy_svg = 'copy_svg',
    arrowRightType = 'arrowRightType',
    arrowLeftType = 'arrowLeftType',
    plus = 'plus',
    info_circle = 'info_circle',
    refresh = 'refresh',
    coins_stacked = 'coins_stacked',
    successIcon = 'successIcon',
    dots_vertical = 'dots_vertical',
    zap_circle = 'zap_circle',
    money = 'money',
    target = 'target',
    starts = 'starts',
    calender = 'calender',
    calenderDate = 'calenderDate',
    coinsHand = 'coinsHand',
    currencyDollar = 'currencyDollar',
    targetOne = 'targetOne',
    prop_challenge_banner = 'prop_challenge_banner',
    close_piqk_alert = 'close_piqk_alert',
    info_icon_prop = 'info_icon_prop',
    shopping_bag = 'shopping_bag',
    close_drawer = 'close_drawer',
    clock_rewind = 'clock_rewind',
    chevron_left_back = 'chevron_left_back',
    alert_circle_icon = 'alert_circle_icon',
    check_circle_icon = 'check_circle_icon',
    x_close = 'x_close',
    chevron_up_double = 'chevron_up_double',
    coins_swap = 'coins_swap',
    shield_dollar = 'shield_dollar',
    search_with_bg = 'search_with_bg',
    search_with_bg_dark = 'search_with_bg_dark',
    sale = 'sale',
    bank_note = 'bank_note',
    tableEmptyStateDark = 'tableEmptyStateDark',

    nav_bar_chart_square = 'nav_bar_chart_square',
    nav_bar_line_chart = 'nav_bar_line_chart',
    nav_bar_plus = 'nav_bar_plus',
    nav_bar_settings = 'nav_bar_settings',
    nav_bar_trophy = 'nav_bar_trophy',
    nav_bar_chart_square_filled = 'nav_bar_chart_square_filled',
    nav_bar_line_chart_filled = 'nav_bar_line_chart_filled',
    nav_bar_settings_filled = 'nav_bar_settings_filled',
    nav_bar_trophy_filled = 'nav_bar_trophy_filled',
    newCalendar = 'newCalendar',
    zap = 'zap',
    refresh_icon = 'refresh_icon',
    pieChart = 'pieChart',
    lightbulbProp = 'lightbulbProp',
    stats_prop = 'stats_prop',
    progress_bar = 'progress_bar',
    step_disabled_light = 'step_disabled_light',
    step_disabled_dark = 'step_disabled_dark',
    step_passed_light = 'step_passed_light',
    step_passed_dark = 'step_passed_dark',
    step_active_light = 'step_active_light',
    step_active_dark = 'step_active_dark',
    step_failed_light = 'step_failed_light',
    step_failed_dark = 'step_failed_dark',

    congratulations = 'congratulations',
    verificationInProgress = 'verificationInProgress',
    verificationComplete = 'verificationComplete',
    accountUnavailable = 'accountUnavailable',
    verificationFailed = 'verificationFailed',
    shield_tick = 'shield_tick',
    shield_plus = 'shield_plus',
    confetti = 'confetti',
    congratulationsKyc = 'congratulationsKyc',
    coinsStacked2 = 'coinsStacked2',
    calenderPlus = 'calenderPlus',
    retry_disabled = 'retry_disabled',
    shareBox = 'shareBox',
    coins = 'coins',
    magic_link_icon = 'magic_link_icon',
    modal_magic_close = 'modal_magic_close',
    magic_link_mail = 'magic_link_mail',
}

export interface IconProps extends IconPropsMat {
    name: IconsType;
}

const Icon: React.FunctionComponent<IconProps> = (props) => {
    const { name, ...rest } = props;
    const theme = useTheme();
    const iconName = theme.palette.type === 'dark' ? `${name}_dark` : name;
    const IconComponent = Icons[iconName] || Icons[name];
    if (!IconComponent) {
        console.error(`Icon is missing: ${name}`);
        return null;
    }
    return React.createElement(IconComponent, rest as any);
};

export default Icon;
