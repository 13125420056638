import { nFloor } from 'helpers/numberFloorAndFormat';
import { EPropCheckoutTypes } from './actionsTypes';
import { PropCheckoutReducerState } from './interfaces';
import { PropGoalTypeEnum } from 'services/generatedClientFromSwagger';

const initialState: PropCheckoutReducerState = {
    planPaymentData: {},
    selectedPackage: undefined,
    selectedPaymentMethod: undefined,
    selectedGoal: undefined,
    selectedAddonsIds: undefined,
    discountCodeValidationLoading: false,
    discountCodeValidation: undefined,
    isDiscountCodeValid: true,
    discountCodeStatus: undefined,
    getPromoCodeLoading: false,
    promotionsPromoCodes: undefined,
};

const PropCheckoutReducer = (state = initialState, { type, payload }: { type: EPropCheckoutTypes; payload: any }) => {
    switch (type) {
        case EPropCheckoutTypes.SET_CHECKOUT_PLAN_DATA:
            return {
                ...state,
                planPaymentData: {
                    isFreePlan: payload.selectedGoal?.type === PropGoalTypeEnum.Free,
                    discountCode: state?.planPaymentData?.discountCode,
                    ...payload,
                },
                isDiscountCodeValid: true,
                selectedPackage: payload.selectedPackages[payload.packageIndex],
                selectedGoal: payload.selectedGoal,
                newOrderAmount: undefined,
                selectedAddonsIds: undefined,
            };
        case EPropCheckoutTypes.SET_SELECTED_PACKAGE:
            const selectedPackageIndex = state?.planPaymentData?.selectedPackages?.findIndex((p) => p._id === payload);
            return {
                ...state,
                selectedPackage: state?.planPaymentData?.selectedPackages?.[selectedPackageIndex ?? 0],
                isDiscountCodeValid: true,
                planPaymentData: {
                    ...state.planPaymentData,
                    packageIndex: selectedPackageIndex,
                    packageId: payload,
                },
                newOrderAmount: undefined,
            };
        case EPropCheckoutTypes.SET_SELECTED_PAYMENT_METHOD:
            return {
                ...state,
                selectedPaymentMethod: payload,
            };
        case EPropCheckoutTypes.SET_SELECTED_ADDONS:
            return {
                ...state,
                selectedAddonsIds: payload,
                newOrderAmount: undefined,
            };
        case EPropCheckoutTypes.VALIDATE_DISCOUNT_CODE_START:
            return {
                ...state,
                discountCodeValidationLoading: true,
            };
        case EPropCheckoutTypes.SET_RETRY_DATA:
            return {
                ...state,
                planPaymentData: {
                    ...state.planPaymentData,
                    ...payload,
                },
            };
        case EPropCheckoutTypes.SET_DISCOUNT_CODE:
            return {
                ...state,
                planPaymentData: {
                    ...state.planPaymentData,
                    discountCode: payload,
                },
            };
        case EPropCheckoutTypes.VALIDATE_DISCOUNT_CODE_SUCCESS:
            return {
                ...state,
                discountCodeValidationLoading: false,
                discountCodeValidation: payload.discountCodeValidation,
                isDiscountCodeValid: payload.isDiscountCodeValid,
                discountCodeStatus: payload.discountCodeStatus,
                newOrderAmount: payload.isDiscountCodeValid ? nFloor(payload.newOrderAmount, 2) : undefined,
                planPaymentData: {
                    ...state.planPaymentData,
                    discountCode: payload.discountCode,
                },
            };
        case EPropCheckoutTypes.VALIDATE_DISCOUNT_CODE_FAIL:
            return {
                ...state,
                discountCodeValidationLoading: false,
            };
        case EPropCheckoutTypes.RESET_DISCOUNT_CODE:
            return {
                ...state,
                discountCodeValidation: undefined,
                isDiscountCodeValid: true,
                planPaymentData: { ...state.planPaymentData, discountCode: undefined },
                newOrderAmount: undefined,
            };
        case EPropCheckoutTypes.GET_PROMO_CODE_START:
            return {
                ...state,
                getPromoCodeLoading: true,
            };
        case EPropCheckoutTypes.GET_PROMO_CODE_SUCCESS:
            return {
                ...state,
                getPromoCodeLoading: false,
                promotionsPromoCodes: payload,
            };
        case EPropCheckoutTypes.GET_PROMO_CODE_FAIL:
            return {
                ...state,
                getPromoCodeLoading: false,
            };
        case EPropCheckoutTypes.RESET_CHECKOUT_DATA:
            return {
                ...state,
                planPaymentData: {
                    ...state.planPaymentData,
                    discountCode: undefined,
                },
                selectedPaymentMethod: undefined,
                selectedGoal: undefined,
                selectedAddonsIds: undefined,
                discountCodeValidation: undefined,
                isDiscountCodeValid: true,
                newOrderAmount: undefined,
            };

        default:
            return state;
    }
};

export default PropCheckoutReducer;
