import config from 'config/common';
import { getTokenFromLocalStorage } from 'helpers/token';
import socketIOClient, { Socket } from 'socket.io-client';
import store from 'store/index';
import { user_refreshUserData } from 'store/user/actions';
import { prop__myPlans_getPlans } from 'features/prop/store/plans/actions';
import { ENotificationsTypes } from 'features/Notifications/store/actionsTypes';
import { challengeUpdated } from 'store/payment/actions';
import { PropPlan } from 'services/generatedClientFromSwagger';
import { sendProductFruitsEvent } from 'helpers/productfruits';

const SOCKET_SERVER_URL = config.api.URL;
class WebSocketConnection {
    public socket: Socket;
    public initialized: boolean = false;

    public connect() {
        this.initConnection();
    }

    private initConnection() {
        this.socket = socketIOClient(SOCKET_SERVER_URL, {
            path: '/api/events',
            transports: ['websocket'],
        });

        this.initialized = true;

        this.socket.on('connect', () => {
            console.log('connected', this.socket.id);
            const token = getTokenFromLocalStorage();
            if (token) {
                this.identifyUser(token);
            }
        });

        this.socket.io.on('reconnect_attempt', () => {
            console.log('reconnect_attempt', this.socket.id);
        });
        this.socket.io.on('error', () => {
            console.log('error', this.socket.id);
        });
        this.socket.io.on('reconnect_error', () => {
            console.log('reconnect_error', this.socket.id);
        });
        this.socket.io.on('reconnect_failed', () => {
            console.log('reconnect_failed', this.socket.id);
        });
        this.socket.io.on('reconnect', () => {
            console.log('reconnect', this.socket.id);
        });
        this.socket.io.on('close', () => {
            console.log('close', this.socket.id);
        });
        this.socket.on('disconnect', () => {
            console.log('disconnect', this.socket.id);

            this.initialized = false;
        });

        this.socket.on('CHALLENGE_UPDATE', async (challenge) => {
            const state = store.getState();
            await store.dispatch(user_refreshUserData() as any);
            const plans: PropPlan[] = await store.dispatch(prop__myPlans_getPlans(false, true) as any);
            console.log('Plans fetched:', plans);
            const selectedChallengeId = state.prop_myPlans?.selectedChallenge?.tceChallengeID;
            console.log('Selected Challenge ID:', selectedChallengeId);
            if (
                selectedChallengeId === challenge.id?.toString() ||
                selectedChallengeId === challenge.orionChallengeId?.toString()
            ) {
                const plan = plans.find((p) => p.propChallenges?.find((c) => c.tceChallengeID === selectedChallengeId));
                if (plan) {
                    plan.propChallenges = plan?.propChallenges?.filter((c) => c.tceChallengeID === selectedChallengeId);
                    console.log('Filtered plan challenges:', plan.propChallenges);
                }
                const iframe = document.getElementById('platformIframe') as HTMLIFrameElement;
                if (iframe && iframe.contentWindow) {
                    const value = { addOns: plan?.addOns, crmChallenge: plan?.propChallenges?.[0], plan: plan?.pPlan };
                    console.log('Posting message to iframe:', value);
                    iframe.contentWindow.postMessage({ type: 'CHALLENGE_UPDATE', value }, '*');
                } else {
                    console.log('Iframe not found or contentWindow is null.');
                }
            } else {
                console.log('No matching challenge found. Skipping update.');
            }
            sendChallengeUpdateEvent(plans, challenge);

            store.dispatch(challengeUpdated() as any);
            console.log('Challenge updated:', challenge);
        });

        this.socket.on('newMessage', (newMessage) => {
            console.log('New message received:', newMessage);
            store.dispatch({ type: ENotificationsTypes.NEW_MESSAGE, payload: newMessage });
        });

        this.socket.on('dataUpdated', (data) => {
            console.log('Data updated:', data);
            switch (data.entity) {
                case 'plan':
                    console.log('Fetching plans and dispatching challenge update.');
                    store.dispatch(prop__myPlans_getPlans(false, true) as any);
                    store.dispatch(challengeUpdated() as any);
                    return;
            }
        });
    }

    public identifyUser = (token: string) => {
        this.socket.emit('identifyUser', token);
    };
}

export default new WebSocketConnection();
async function sendChallengeUpdateEvent(plans: PropPlan[], challenge: any) {
    const plansForEvents = plans?.find((p) =>
        p?.propChallenges?.find((c) => c.tceChallengeID === `${challenge?.orionChallengeId}`)
    );
    if (plansForEvents) {
        const eventChallenge = plansForEvents?.propChallenges?.find(
            (c) => c.tceChallengeID === `${challenge?.orionChallengeId}`
        );
        console.log('sendChallengeUpdateEvent:', plansForEvents.propChallenges);
        if (eventChallenge) {
            sendProductFruitsEvent(eventChallenge);
        }
    }
}
