import React, { FunctionComponent, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom'; // Import IntercomProps type
import { connect } from 'react-redux';
import { RootState } from 'store/index';
import { user_displayCheckoutFTDLayout, user_userInfo } from 'store/user/selectors';
import { AccountInfoParsed, PropGoal } from 'services/generatedClientFromSwagger';
import moment from 'moment';

type IntercomChatProps = {
    userInfo: AccountInfoParsed;
    displayCheckoutFTDLayout: boolean;
    selectedGoal: PropGoal;
};

const IntercomChat: FunctionComponent<IntercomChatProps> = ({ userInfo, displayCheckoutFTDLayout }) => {
    const { boot, shutdown, update, isOpen, trackEvent } = useIntercom();

    const ftdExists = !displayCheckoutFTDLayout ? 'Yes' : 'No';

    useEffect(() => {
        const adjustIntercomForMobile = () => {
            const intercomStyles = document.createElement('style');
            intercomStyles.innerHTML = `
                @media screen and (max-width: 768px) {
                    .intercom-lightweight-app-launcher {
                        bottom: 72px !important;
                        right: 15px !important;
                    }
                    .intercom-namespace div {
                        bottom: 72px !important;
                        right: 15px !important;
                    }
                }
            `;
            document.head.appendChild(intercomStyles);

            return () => {
                document.head.removeChild(intercomStyles);
            };
        };

        const formattedCreatedAt = userInfo.custom_field_createdon
            ? moment(userInfo.custom_field_createdon).format('MMM DD, YYYY HH:mm')
            : undefined;

        boot({
            userId: userInfo.id ?? '',
            name: userInfo.lastName ?? '',
            email: userInfo.email ?? '',
            createdAt: formattedCreatedAt,
            customAttributes: {
                ftd_exists: ftdExists,
                'FTD Exists': ftdExists,
            },
        });

        adjustIntercomForMobile();

        trackEvent('onShow', {
            email: userInfo.email ?? '',
            userId: userInfo.id,
            name: userInfo.firstName ?? '',
            createdAt: formattedCreatedAt,
            ftd_exists: ftdExists,
        });

        if (isOpen && typeof update === 'function') {
            update({
                email: userInfo?.email ?? '',
                userId: userInfo?.id,
                customAttributes: {
                    ftd_exists: ftdExists,
                },
            });
        }

        return () => {
            shutdown();
        };
    }, [
        boot,
        shutdown,
        trackEvent,
        userInfo.email,
        userInfo.id,
        ftdExists,
        isOpen,
        update,
        userInfo.custom_field_createdon,
        userInfo.lastName,
        userInfo.firstName,
    ]);

    return <></>;
};

const mapStateToProps = (state: RootState) => ({
    userInfo: user_userInfo(state),
    displayCheckoutFTDLayout: user_displayCheckoutFTDLayout(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(IntercomChat));
