import { deleteTokenFromLocalStorage, saveTokenToLocalStorage } from 'helpers/token';
import { LoginTypes, RegisterType, SocialLoginTypes } from 'interfaces/main';
import { EAuthTypes } from './actionsTypes';
import axios from 'services/axios';
import { AuthFormFields, AuthLocalRequest } from './interfaces';
import { system_resetApp } from 'store/system/actions';
import { RootState } from '..';
import { auth_ipData } from './selectors';
import { mixPanelEventsTypes } from 'helpers/analyticsManager';
import { jwtDecode as decode } from 'jwt-decode';
import { getLinkFromSessionStorage, saveLinkToSessionStorage } from 'helpers/linkRemember';
import { GetLeadDetailsResponse, TryRegisterLeadAccountRequest } from 'services/generatedClientFromSwagger';
import i18n from 'i18n';
import LuckyOrange from 'services/LuckyOrange';
import config from 'config/common';
import LogRocket from 'logrocket';
import { dispatchCustomEvent } from 'helpers/customEventHelpers';
import { TokenPayload } from 'helpers/jwtParser';
import analyticsManager from 'helpers/analyticsManager';

const onWindowUnload = () => {
    if (config?.redirectToExternalEndpoint?.url) {
        deleteTokenFromLocalStorage();
    }
};

export const auth_localRegister = (request: {
    loginLocalRequest?: AuthLocalRequest;
    accountType: RegisterType;
    promoCode?: string;
    agentCode?: string;
    sourceId?: string;
    subAffiliate?: string;
    redirect?: string;
    leadDetails?: GetLeadDetailsResponse;
    mi?: object;
    referralCode?: string;
}) => {
    return async (dispatch, getState) => {
        const state: RootState = getState();
        const ipData = auth_ipData(state);
        const {
            accountType,
            loginLocalRequest,
            promoCode,
            agentCode,
            sourceId,
            subAffiliate,
            redirect,
            leadDetails,
            mi,
            referralCode,
        } = request;

        try {
            dispatch({
                type: EAuthTypes.REGISTER_START,
                payload: LoginTypes.local,
            });
            const registerRes = await axios.AuthApi.authControllerLocalRegister({
                ...loginLocalRequest,
                accountType: accountType as any,
                promotionCode: promoCode,
                countryCode: ipData?.countryCode || '',
                currency: ipData?.currency || '',
                agentCode,
                sourceId,
                referrer: document.referrer,
                subAffiliate,
                leadDetails,
                mi: mi,
                language: i18n.language,
                referralCode,
            });
            if (!redirect && config.redirectAfterRegister && !getLinkFromSessionStorage()) {
                saveLinkToSessionStorage(config.redirectAfterRegister, registerRes.data.jwt);
            }
            if (redirect) {
                saveLinkToSessionStorage(redirect, registerRes.data.jwt);
            }
            if (!registerRes.data.jwt) {
                throw new Error('No JWT');
            }

            saveTokenToLocalStorage(registerRes.data.jwt);
            dispatch({ type: EAuthTypes.REGISTER_SUCCESS });
            analyticsManager.identify(decode<TokenPayload>(registerRes.data.jwt)?.user_id);
            analyticsManager.trackEvent(mixPanelEventsTypes.REGISTER_LOCAL, {
                promoCode,
                accountType,
                ...loginLocalRequest,
                status: true,
            });
            dispatchCustomEvent('register', { userId: decode<TokenPayload>(registerRes.data.jwt)?.user_id });
        } catch (error) {
            let errorMessage = error;
            if (error instanceof Error) {
                errorMessage = error.toString();
            }
            analyticsManager.trackEvent(mixPanelEventsTypes.REGISTER_LOCAL, {
                promoCode,
                accountType,
                ...loginLocalRequest,
                status: false,
                error: errorMessage,
            });
            return dispatch({
                type: EAuthTypes.REGISTER_FAILED,
                payload: {
                    errors: {
                        [AuthFormFields[`mainRegister_local`]]: {
                            error: true,
                            error_label: `common:errors.register.local`,
                        },
                    },
                },
            });
        }
    };
};

export const auth_socialRegister = (request: {
    token: string;
    socialType: SocialLoginTypes;
    accountType: RegisterType;
    promoCode?: string;
    agentCode?: string;
    sourceId?: string;
    subAffiliate?: string;
    redirect?: string;
    leadDetails?: GetLeadDetailsResponse;
    mi?: object;
}) => {
    return async (dispatch, getState) => {
        const state: RootState = getState();
        const ipData = auth_ipData(state);
        const {
            token,
            socialType,
            accountType,
            promoCode,
            agentCode,
            sourceId,
            subAffiliate,
            redirect,
            leadDetails,
            mi,
        } = request;
        try {
            dispatch({
                type: EAuthTypes.REGISTER_START,
                payload: SocialLoginTypes[socialType],
            });
            const registerRes = await axios.AuthApi.authControllerSocialRegister({
                token,
                socialType: socialType as any,
                accountType: accountType as any,
                promotionCode: promoCode,
                countryCode: ipData?.countryCode || '',
                currency: ipData?.currency || '',
                agentCode,
                sourceId,
                referrer: document.referrer,
                subAffiliate,
                leadDetails,
                mi: mi,
                language: i18n.language,
            });
            if (!redirect && config.redirectAfterRegister && !getLinkFromSessionStorage()) {
                saveLinkToSessionStorage(config.redirectAfterRegister, registerRes.data.jwt);
            }
            if (redirect) {
                saveLinkToSessionStorage(redirect, registerRes.data.jwt);
            }
            saveTokenToLocalStorage(registerRes.data.jwt);
            dispatch({ type: EAuthTypes.REGISTER_SUCCESS });

            if (!registerRes.data.jwt) {
                throw new Error('No JWT');
            }
            LogRocket.identify(decode<TokenPayload>(registerRes.data.jwt)?.user_id);
            analyticsManager.identify(decode<TokenPayload>(registerRes.data.jwt)?.user_id);
            analyticsManager.trackEvent(mixPanelEventsTypes.REGISTER_SOCIAL, { ...request, status: true });
            dispatchCustomEvent('register', { userId: decode<TokenPayload>(registerRes.data.jwt)?.user_id });
        } catch (error) {
            let errorMessage = error;
            if (error instanceof Error) {
                errorMessage = error.toString();
            }
            analyticsManager.trackEvent(mixPanelEventsTypes.REGISTER_SOCIAL, {
                ...request,
                status: false,
                error: errorMessage,
            });
            return dispatch({
                type: EAuthTypes.REGISTER_FAILED,
                payload: {
                    errors: {
                        [AuthFormFields[`mainRegister_${socialType}`]]: {
                            error: errorMessage,
                            error_label: `common:errors.register.${socialType}`,
                        },
                    },
                },
            });
        }
    };
};

export const auth_registerSuccess = () => {
    return async (dispatch) => {};
};

export const auth_registerFailed = (loginType: LoginTypes) => {
    return async (dispatch) => {
        dispatch({
            type: EAuthTypes.REGISTER_FAILED,
            payload: {
                errors: {
                    [AuthFormFields[`mainRegister_${loginType}`]]: {
                        error: true,
                        error_label: `common:errors.register.${loginType}`,
                    },
                },
            },
        });
    };
};

//Login Actions
export const auth_loginStart = (request: {
    loginType: LoginTypes;
    token?: string;
    loginLocalRequest?: AuthLocalRequest;
}) => {
    return async (dispatch) => {
        const { loginType, loginLocalRequest, token } = request;
        const { email, password } = loginLocalRequest || {};
        try {
            dispatch({
                type: EAuthTypes.LOGIN_START,
                payload: {
                    type: loginType,
                },
            });
            const loginRes = await axios.AuthApi.authControllerLogin({
                type: loginType as any,
                token,
                email,
                password,
            });
            const jwt = loginRes?.data?.jwt;

            if (!jwt) {
                throw new Error('');
            }
            saveTokenToLocalStorage(jwt);
            if (config.redirectAfterLogin && !getLinkFromSessionStorage()) {
                saveLinkToSessionStorage(config.redirectAfterLogin, jwt);
            }
            dispatch({
                type: EAuthTypes.LOGIN_SUCCESS,
            });

            const decodedJWT = decode<TokenPayload>(jwt);
            LogRocket.identify(decodedJWT?.user_id);
            analyticsManager.identify(decodedJWT?.user_id);
            analyticsManager.trackEvent(mixPanelEventsTypes.LOGIN, {
                loginType: decodedJWT?.type,
                status: true,
            });
        } catch (error) {
            let errorMessage = error;
            if (error instanceof Error) {
                errorMessage = error.toString();
            }
            analyticsManager.trackEvent(mixPanelEventsTypes.LOGIN, {
                loginType,
                status: false,
                error: errorMessage,
            });
            return dispatch({
                type: EAuthTypes.LOGIN_FAILED,
                payload: {
                    errors: {
                        [AuthFormFields[`mainLogin_${loginType}`]]: {
                            error: errorMessage,
                            error_label: `common:errors.login.${loginType}`,
                        },
                    },
                },
            });
        }
    };
};

export const auth_loginSuccess = () => {
    return async (dispatch) => {};
};

export const auth_loginFailed = (loginType: LoginTypes) => {
    return async (dispatch) => {
        dispatch({
            type: EAuthTypes.LOGIN_FAILED,
            payload: {
                errors: {
                    [AuthFormFields[`mainLogin_${loginType}`]]: {
                        error: true,
                        error_label: `common:errors.login.${loginType}`,
                    },
                },
            },
        });
    };
};

//Common Actions
export const auth_socialRequest = (type: LoginTypes) => {
    return async (dispatch) => {
        dispatch({
            type: EAuthTypes.SOCIAL_REQUEST,
            payload: {
                type,
            },
        });
    };
};

export const auth_logout = () => {
    LuckyOrange.destroy();
    return async (dispatch) => {
        analyticsManager.trackEventGeneric(mixPanelEventsTypes.LOGOUT);
        if (config?.redirectToExternalEndpoint?.url) {
            window.onbeforeunload = onWindowUnload;
            window.location.href = config?.redirectToExternalEndpoint?.url;
        } else {
            dispatch(system_resetApp());
        }
    };
};

export const auth_setError = (field: AuthFormFields, error: boolean, error_label: string) => {
    return async (dispatch) => {
        dispatch({
            type: EAuthTypes.SET_FORM_ERROR,
            payload: {
                field,
                error,
                error_label,
            },
        });
    };
};

export const auth_resetError = (field: AuthFormFields) => {
    return async (dispatch) => {
        dispatch({
            type: EAuthTypes.RESET_FORM_ERROR,
            payload: {
                field,
            },
        });
    };
};

export const auth_forgotPassword = (email: string) => {
    return async (dispatch) => {
        dispatch({
            type: EAuthTypes.START_LOADER,
        });
        try {
            await axios.AuthApi.authControllerForgotPassword({
                email,
            });
            dispatch({
                type: EAuthTypes.STOP_LOADER,
            });
            return true;
        } catch (error) {
            dispatch({
                type: EAuthTypes.STOP_LOADER,
            });
            return true;
        }
    };
};

export const auth_magicLink = (email: string) => {
    return async (dispatch) => {
        dispatch({
            type: EAuthTypes.START_LOADER,
        });
        try {
            await axios.AuthApi.authControllerSendMagicLink({
                email,
            });
            dispatch({
                type: EAuthTypes.STOP_LOADER,
            });
            return true;
        } catch (error) {
            dispatch({
                type: EAuthTypes.STOP_LOADER,
            });
            return true;
        }
    };
};

export const auth_resetPassword = (token: string, password: string, redirect?: string) => {
    return async (dispatch) => {
        dispatch({
            type: EAuthTypes.START_LOADER,
        });
        try {
            const res = await axios.AuthApi.authControllerRestPassword({
                password,
                token,
            });
            dispatch({
                type: EAuthTypes.STOP_LOADER,
            });

            const jwt = res.data?.jwt;
            if (!jwt) {
                return false;
            }

            if (redirect) {
                saveLinkToSessionStorage(redirect, jwt);
            }
            saveTokenToLocalStorage(jwt);
            dispatch({ type: EAuthTypes.LOGIN_SUCCESS });
            return true;
        } catch (error: any) {
            dispatch({
                type: EAuthTypes.STOP_LOADER,
            });
            throw new Error(error?.response?.data?.message);
        }
    };
};

export const auth_getIpData = () => {
    return async (dispatch, getState) => {
        const state: RootState = getState();
        const ipData = auth_ipData(state);
        try {
            if (ipData) return;
            dispatch({ type: EAuthTypes.IP_DATA_START });
            const res = await axios.AuthApi.authControllerGetIp();
            dispatch({
                type: EAuthTypes.IP_DATA_SUCCESS,
                payload: res.data,
            });
        } catch (error) {
            dispatch({ type: EAuthTypes.IP_DATA_FAILED });
        }
    };
};

export const auth_validatePromoCode = (promotionCode: string) => {
    return async (dispatch) => {
        try {
            await axios.CrmPublicApi.crmPublicControllerValidatePromoCode({ promoCode: promotionCode });
            return true;
        } catch (error) {
            return false;
        }
    };
};

export const auth_tryRegisterLeadAccount = (payload: TryRegisterLeadAccountRequest) => {
    return async () => {
        try {
            await axios.AuthApi.authControllerTryRegisterLeadAccount(payload);
        } catch (error) {}
    };
};

export const auth_getLeadDetails = (leadId: string) => {
    return async (dispatch) => {
        try {
            const res = await axios.CrmPublicApi.crmPublicControllerGetLeadDetails({ leadId });
            return res.data.getLeadDetailsResult || undefined;
        } catch (error) {
            return undefined;
        }
    };
};
