import { isTablet } from 'helpers/device_helper';
import { Pages } from 'interfaces/main';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { user_displayCheckoutFTDLayout } from 'store/user/selectors';
const pagesNames = new Set<string>([`/prop/${Pages.PROP__CHECKOUT}`]);
export const useHideLayout = () => {
    const displayCheckoutFTDLayout = useSelector(user_displayCheckoutFTDLayout);
    const { pathname } = useLocation();
    return useMemo(() => {
        const matchPath = pathname ? pagesNames.has(pathname as Pages) : false;
        //if its desktop app will return true
        //if its mobile app and the page is not checkout will return true
        return !isTablet() && displayCheckoutFTDLayout && matchPath;
    }, [displayCheckoutFTDLayout, pathname]);
};
