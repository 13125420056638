import breakpoints from 'muiTheme/config/breakpoints';
import { CSSProperties } from '@material-ui/styles';

export function isMobileDevice(css: CSSProperties | undefined = undefined): any {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (!css) {
        return isMobile;
    } else {
        return isMobile ? { '&': { ...css } } : {};
    }
}

export function isMobile(css: any = undefined): any {
    const maxWidth = breakpoints.sm;
    const key = `@media (max-width: ${maxWidth}px)`;
    if (!css) {
        return window.innerWidth <= maxWidth;
    } else {
        return { [key]: { ...css } };
    }
}

export function isBigDesktop(css: any = undefined): any {
    const minWidth = breakpoints.xl;
    const key = `@media (min-width: ${minWidth}px)`;
    if (!css) {
        return window.innerWidth >= minWidth;
    } else {
        return { [key]: { ...css } };
    }
}

export function isDesktop(css: any = undefined): any {
    const minWidth = breakpoints.lg;
    const key = `@media (min-width: ${minWidth}px)`;
    if (!css) {
        return window.innerWidth >= minWidth;
    } else {
        return { [key]: { ...css } };
    }
}

export function isLaptop(css: any = undefined): any {
    const minWidth = breakpoints.md;
    const key = `@media (min-width: ${minWidth}px)`;
    if (!css) {
        return window.innerWidth >= minWidth;
    } else {
        return { [key]: { ...css } };
    }
}

export function isTablet(css: any = undefined): any {
    const minWidth = breakpoints.sm;
    const key = `@media (min-width: ${minWidth}px)`;
    if (!css) {
        return window.innerWidth >= minWidth;
    } else {
        return { [key]: { ...css } };
    }
}

export function customBreakPoint(breakpoint: number, css: any = undefined): any {
    const key = `@media (min-width: ${breakpoint}px)`;
    if (!css) {
        return window.innerWidth >= breakpoint;
    } else {
        return { [key]: { ...css } };
    }
}

export const isIOS = () =>
    (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) || /iPhone/i.test(navigator.userAgent);

export const isIE = (css) => {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');

    if (css) {
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
            return { ...css };
        } else {
            return {};
        }
    } else {
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
            return true;
        } else {
            return false;
        }
    }
};

export const isRtl: (css?) => any = (css?) => {
    const isRtl = document.body.dir === 'rtl';

    if (css) {
        return {
            '[dir=rtl] &': { ...css },
        };
    } else {
        return isRtl;
    }
};

export const leftOrRight = (dir: 'ltr' | 'rtl', position: 'start' | 'end', value: string | number) => {
    switch (true) {
        case dir === 'ltr' && position === 'start':
        case dir === 'rtl' && position === 'end':
            return {
                left: value,
            };
        case dir === 'ltr' && position === 'end':
        case dir === 'rtl' && position === 'start':
            return {
                right: value,
            };

        default:
            return {};
    }
};

export const cssStart = (dir: 'ltr' | 'rtl', property: 'padding' | 'margin' | 'border', value: string) => {
    const LeftOrRight = dir === 'ltr' ? 'Left' : 'Right';
    return {
        [`${property}${LeftOrRight}`]: value,
    };
};

export const cssEnd = (dir: 'ltr' | 'rtl', property: 'padding' | 'margin' | 'border', value: string) => {
    const LeftOrRight = dir === 'ltr' ? 'Right' : 'Left';
    return {
        [`${property}${LeftOrRight}`]: value,
    };
};

export const oppositeCss = (dir: 'ltr' | 'rtl', property: 'padding' | 'margin' | 'border', value: string) => {
    const fixSpacesValue = value.trim().replace(/\s\s+/g, ' ');
    const valueSpaces = fixSpacesValue.split(' ').length;
    let resultValue = fixSpacesValue;
    if (dir === 'rtl' && valueSpaces === 4) {
        const [a, b, c, d] = fixSpacesValue.split(' ');
        resultValue = `${a} ${d} ${c} ${b}`;
    }
    return {
        [property]: resultValue,
    };
};

export const customGap = (gap: number, type: 'vertical' | 'horizontal') => {
    switch (type) {
        case 'vertical':
            return {
                '& > *': {
                    '&:not(:last-child)': {
                        margin: `0 0 ${gap}rem 0`,
                    },
                },
            };
        case 'horizontal':
            return {
                '& > *': {
                    '&:not(:last-child)': {
                        margin: isRtl() ? `0 0 0 ${gap}rem` : `0 ${gap}rem 0 0`,
                    },
                },
            };
    }
};

export function isLandscape(css: any = undefined): any {
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isLandscape = window.innerWidth > window.innerHeight;

    if (!css) return isLandscape && isMobileDevice;
    return isLandscape && isMobileDevice ? { '&': { ...css } } : {};
}
