import { Hidden } from '@material-ui/core';
import classnames from 'classnames';
import config from 'config/common';
import React from 'react';
import Logo from '../components/Logo';
import ThemeSwitch from '../components/ThemeSwitch';
import useStyles from '../header.styles';
import LanguageSelect from 'components/SideMenu/components/LanguageSelect';

export interface PublicHeaderProps {
    menuState: boolean;
}

const PublicHeader: React.FunctionComponent<PublicHeaderProps> = ({ menuState }) => {
    const classes = useStyles();

    return (
        <header
            className={classnames(
                config?.logoSettings?.position ? classes[`logo_position_${config?.logoSettings?.position}`] : '',
                classes.header_container,
                classes.header_without_border
            )}
        >
            <div
                className={classnames(
                    classes.header_left_section,
                    config?.logoSettings?.position
                        ? classes[`header_left_section_${config?.logoSettings?.position}_logo`]
                        : ''
                )}
            >
                <Logo alwaysShow />
            </div>

            <div className={classes.header_right_section}>
                {!config.featuresFlags['disableDarkMode'] && (
                    <Hidden xsDown>
                        <ThemeSwitch />
                    </Hidden>
                )}
                <LanguageSelect menuState={menuState} />
            </div>
        </header>
    );
};

export default PublicHeader;
