/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Addons } from './addons';
import { PlanDiscountLabel } from './plan-discount-label';
import { PropChallengeCP } from './prop-challenge-cp';
import { RetrySettings } from './retry-settings';

/**
 *
 * @export
 * @interface PropGoal
 */
export class PropGoal {
    /**
     *
     * @type {string}
     * @memberof PropGoal
     */
    _id?: string;
    /**
     *
     * @type {string}
     * @memberof PropGoal
     */
    type: PropGoalTypeEnum;
    /**
     *
     * @type {number}
     * @memberof PropGoal
     */
    limitAccountsNum?: number;
    /**
     *
     * @type {number}
     * @memberof PropGoal
     */
    maxFreeTrialLimit?: number;
    /**
     *
     * @type {Array<PropChallengeCP>}
     * @memberof PropGoal
     */
    challenges: Array<PropChallengeCP>;
    /**
     *
     * @type {string}
     * @memberof PropGoal
     */
    title: string;
    /**
     *
     * @type {Addons}
     * @memberof PropGoal
     */
    addons?: Addons;
    /**
     *
     * @type {RetrySettings}
     * @memberof PropGoal
     */
    retrySettings?: RetrySettings;
    /**
     *
     * @type {boolean}
     * @memberof PropGoal
     */
    active?: boolean;
    /**
     *
     * @type {number}
     * @memberof PropGoal
     */
    positionId?: number;
    /**
     *
     * @type {PlanDiscountLabel}
     * @memberof PropGoal
     */
    planDiscountLabel?: PlanDiscountLabel;
}

/**
 * @export
 * @enum {string}
 */
export enum PropGoalTypeEnum {
    Paid = 'Paid',
    Free = 'Free',
}
